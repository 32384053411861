.accordion .accordion-header button{
  padding: 3px 0px;
  background-color: transparent;
}

.badge .badge-circle {
    display: inline-flex;
    padding-bottom: 100%;
    background-color: inherit;
    color: inherit;
    border: none;
    width: 7px; /* Adjust this value to fit the desired badge size */
    height: 8px; /* Adjust this value to fit the desired badge size */
    border-radius: 50%;
    justify-content: center;
    align-items: center;
  }
  
  .badge .badge-circle::after {
    content: '';
    display: block;
    background-color: inherit;
    width: 60%;
    height: 60%;
    border-radius: 50%;

  }
  

  .shopping-list-link .shopping-list-badge {
    top: 7px;
    right: 28%;
    z-index: 2;
    background-color: #d6293e;
   }
   
   .shopping-list-link .shopping-list-count {
    font-size: 9px;
    left: 1px;
    padding: 3px;
    background-color: #d6293e;
   }


   
   .favorites-link .favorites-badge {
    top: 7px;
    right: 62%;
    z-index: 2;
    background-color: #d6293e;
   }
   
   .favorites-link .favorites-count {
    font-size: 9px;
    left: 1px;
    padding: 3px;
    background-color: #d6293e;
   }


   .scrollbar {
    overflow-y: auto;
  }
  
  .scrollbar::-webkit-scrollbar {
    width: 12px;
    border: none;
  }
     

  .scrollbar::-webkit-scrollbar-track {
    background-color: #f1f1f1;
  }
  
  .scrollbar::-webkit-scrollbar-thumb {
    background-color: #888;
    border-radius: 6px;
  }
  
  .scrollbar::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
  
   
   .fs-icon{
    font-size: 2.1875rem;
  }
  
  
  @media screen and (max-width:540px) {
    .fs-icon{
      font-size: 1.8rem;
    }
    .align{
      margin-bottom: 1%;
    }
   }
  @media screen and (max-width:420px) {
    .fs-icon{
      font-size: 1.75rem;
    }
    .align{
      margin-bottom: 1%;
    }
   }
  @media screen and (max-width:400px) {
    .fs-icon{
      font-size: 1.6rem;
    }
   }
  @media screen and (max-width:380px) {
    .fs-icon{
      font-size: 1.25rem;
    }
    .icon-md{
      font-size:65%; 
    }
    .align{
      margin-bottom: 1%;
    }
   }
   
