.custom-checkbox {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    margin: 0;
    padding: 0;
    cursor: pointer;
   }
   
   .custom-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
   }
   
   .custom-checkbox .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 20px;
    width: 20px;
    background-color: #eee;
   }
   
   .custom-checkbox input:checked ~ .checkmark {
    background-color: #2196F3;
   }
   
   .custom-checkbox input:checked ~ .checkmark:after {
    content: "";
    position: absolute;
    display: block;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
    top: 5px;
    left: 10px;
   }
   