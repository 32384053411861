

.list-group > .span1 {
    background-color: #a1a1a8;
    border: none;
    font-size: 0.80rem;
    transition: 0.5s;
    text-align: center;
}
.list-group > .span1:hover{
    background-color: rgb(142, 167, 228);
}
.btn-container{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100%;
}
.linkstyle{
    display: flex;
    justify-content: center;
    color: white;
    width: 30%;
    height: 20%;
    border-radius: 0.4rem;
    height: 100%;
    background-color: rgb(76, 113, 207);
    padding:2%;
}
.animate{
    justify-content: flex-start;
    transition : linear;
    display: flex;
    justify-content: flex-start;
}

