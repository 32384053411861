/* * {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
} */

/* clearfix */
/* .grid:after {
  content: "";
  display: block;
  clear: both;
} */

/* ---- grid-item ---- */

/* 
ex. userCard
.grid-item-sm {
  width: 50%;
  height: 200px;
  padding: 12px;
} 
*/

/* FOR SM (PHONE)  */
/* .grid-sizer,
.grid-item {
  width: 20%; */
/* padding: 12px; */
/* }
.grid-item--width2 {
  width: 50%;
}

.detailCard {
  height: 380px;
} */

/* FOR ABOVE SM */
/* @media screen and (min-width: 480px) {
  .grid-sizer,
  .grid-item {
    width: 20%;
  }
  .grid-item--width2 {
    width: 40%;
  }

  .detailCard {
    height: 420px;
  }
}

.scroll {
  overflow: hidden;
}
.scroll:hover {
  overflow-y: scroll;
} */

* {
  box-sizing: border-box;
}

body {
  font-family: sans-serif;
}

/* ---- grid ---- */

.grid {
  background: #eee;
  max-width: 1200px;
}

/* clearfix */
.grid:after {
  content: "";
  display: block;
  clear: both;
}

/* ---- grid-item ---- */

.grid-item {
  width: 20%;
}

.grid-sizer,
.grid-item {
  width: 20%;
}

.grid-item--width2 {
  width: 40%;
}
.grid-item--width3 {
  width: 60%;
}
.grid-item--width4 {
  width: 80%;
}

/* .grid-item--height2 {
  height: 200px;
}
.grid-item--height3 {
  height: 260px;
}
.grid-item--height4 {
  height: 360px;
} */

/* .grid-item--gigante {
  width: 320px;
  height: 360px;
  z-index: 2; 
} */
